/* html {
  font-size: 0.95rem;
} */
.color-trans {
    background: repeating-linear-gradient( 45deg,
      transparent,transparent 2px,rgba(200,200,200,0.5) 2px,
      rgba(200,200,200,0.5) 4px );
    background-color: rgba(0, 0, 0, 0);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-image: repeating-linear-gradient(45deg,
      transparent, transparent 2px, rgba(200, 200, 200, 0.5) 2px,
      rgba(200, 200, 200, 0.5) 4px);
    background-size: auto;
    background-origin: padding-box;
    background-clip: border-box;
}

.color {
    width: 30px;
    height: 24px;
    border: thin solid
    #ccc;
    margin-right: 3px;
}

/*
.App .row.header {
  background-color: #05e;
  color: #000;
}

.ui.header .sub.header {
  color: #eee;
}

.Footer {
  background-color: #05e;
  color: #eee;
}

.App .ui.dropdown.accountIcon {
  margin: 8px 8px 8px 12px;
}

.row.header a, .Footer a {
  color: #eee;
}

.row.header a:hover, .row.header a:active, .row.header a:focus,
.Footer a:hover, .Footer a:active, .Footer a:focus {
  color: #111;
}

.ui.tag.label {
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: #fa0;
  color: #111;
}

.ui.tag.label:before {
 width:1.65em;
 height:1.65em;
 border-radius: 0.285714rem;
}

a.ui.label:hover {
  background-color: #05e;
  color: #eee;
}

.ui.button {
  background-color: #09e;
  color: #111;
}

.ui.button:hover, .ui.button:focus, .ui.button:active {
  background-color: #05e;
  color: #eee;
}
*/
